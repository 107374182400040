<template>
  <div class="work">
    <article class="row">
      <div class="column column-50">
        <h1 class="headline">Work</h1>
        <h3 class="subline">Latest projects</h3>
        <p>A selection of projects I worked on. Most projects have been done in an agency setting, yet I was fully responsible for ideation, design, development and post-launch support.</p>
      </div>
    </article>
    <h3 class="headline">GaBee</h3>
    <h4 class="subline">AI-powered personal assistant</h4>
    <p>I was contracted to take part in the MVP stage of GaBee, an AI-driven personal assistant for your smartphone.
      <br>Much like Amazon's Alexa and other Voice Assistants, Gabee would be controlled either via Voice or directly via the UI.
      <br>Gabee however, would be able to find the perfect date and time for your next meeting.
      <br>Simply adding who you want to meet would allow GaBee to find the right time for all participants.
    </p>
    <p>For GaBee I was tasked with UI and UX Design, aswell as creation of various marketing assets for the landing page.</p>
    <article class="row">
      <div class="column column-50">
        <img
          src="@/assets/img/projects/filipbasara_gabee_interface_mock.jpg"
          alt="Person touching the display of a smartphone"
        >
      </div>
      <div class="column column-50">
        <img
          src="@/assets/img/projects/filipbasara_gabee_interface_2_mock.jpg"
          alt="Person holding a smartphone with both hands"
        >
      </div>
    </article>
    <article class="row">
      <div class="column column-50">
        <img
          src="@/assets/img/projects/filipbasara_gabee_interface_3_mock.jpg"
          alt="Person holding a smartphone with their right hand"
        >
      </div>
      <div class="column column-50">
        <img
          src="@/assets/img/projects/filipbasara_gabee_watch_mock.jpg"
          alt="Person looking at their Smartwatch"
        >
      </div>
    </article>
    <h3 class="headline">Agency work</h3>
    <h4 class="subline">Projects for small-business and industry clients</h4>
    <p>A selection of projects I worked on while being employed in agencies.</p>
    <article class="row">
      <div class="column">
        <h4>Holzmanufaktur Rottweil Recruiting</h4>
        <p>Holzmanufaktur Rottweil is a carpenting business in Rottweil, Germany. I was given the oppurtunity to design and develop the new career website.
          <br>
          <small>Sketch, Marvel, Contao CMS, jQuery</small>
        </p>
      </div>
      <div class="column">
        <h4>LOFT48° Werbeagentur</h4>
        <p>The agency I work for was in need of a new website. Based on the same content architecture, I modernized and optimized the Wordpress theme.
          <br>
          <small>Wordpress, jQuery</small>
        </p>
      </div>
    </article>
  </div>
</template>
 
<script>
// @ is an alias to /src

export default {
  name: "work",
  components: {}
};
</script>
 